export default {
  'report': 'Relatório',
  'new': 'Novo',
  'sum': 'Soma',
  'time': 'Tempo',
  'quantity': 'Quantidade',
  'aggregated': 'Agregado',
  'detailed': 'Detalhado',
  'showNAlarms': 'Mostrar <b>{0}</b> alarmes mais longos',
  'showNLongest': 'Mostrar o <b> {0} </b> mais longo',
  'showOthers': 'Mostrar outros',
  'hideOthers': 'Ocultar outros',
  'sumOfSmaller': 'Soma dos outros',
  'dropHereToCreateNew': 'Arraste e solte aqui para criar um novo gráfico',
  'completedOrders': 'Pedidos concluídos',
  'history': 'Histórico',
  'usedEnergy': 'Energia usada',
  'addPersonal': 'Painel pessoal',
  'addFactory': 'Painel de controle de fábrica',
  'unitConversionNotComplete': 'Conversão de unidade não concluída',
  'unitConversionComplete': 'Conversão de unidade completa',
  'unableToFullyConvertUnitMessage': 'Não podemos converter toda a produção para \'{unit}\', verifique a estrutura de embalagem dos SKUs que foram produzidos durante este período',
  'work': 'Trabalhado',
  'plannedDowntime': 'Planejado',
  'unplannedDowntime': 'Não planejado',
  'expected': 'Esperado',
  'rootElement': 'Elemento raiz',
  'performanceImpact': 'Perda de desempenho',
  'availabilityImpact': 'Perda de disponibilidade',
  'utilizationImpact': 'Perda de utilização',
  'minorStops': 'Pequenas paradas',
  'oeeLoss': {
    'oeeLoss': 'Perda de OEE',
    'productionLoss': 'Perda de produção',
    'undefinedIssue': 'Tipo de problema indefinido',
    'utilizationLosses': 'Perdas de uso',
    'availabilityLosses': 'Perdas de disponibilidade',
    'performanceLosses': 'Perdas de desempenho',
    'qualityLosses': 'Perdas de qualidade',
    'speedLoss': 'Perda de velocidade',
    'qualityLoss': 'Perda de qualidade',
    'speedIncrease': 'Ganho de aumento de velocidade',
    'combined': 'Combinado',
    'byMachine': 'Dividir por linha/máquina',
    'byProblem': 'Dividir por problema',
    'byState': 'Dividir por status',
    'base': 'Tempo da base',
    'time': {
      'totalTime': 'Tempo total',
      'scheduledTime': 'Horário agendado',
      'operatingTime': 'Tempo operacional',
      'productionTime': 'Tempo de produção',
      'effectiveTime': 'Tempo efetivo'
    },
    'performanceStops': 'Paradas com perda de desempenho',
    'qualityStops': 'Paradas com perda de qualidade',
    'calculatedBasedOnTotalAvailabilityLoss': 'Calculado com base na perda total de disponibilidade',
    timeLoss: "Perda de tempo",
    splitBy: "Dividir por",
    byClassification: "Classificação",
    classificationInfoTooltip:
      'Para usar diferentes tipos de classificação, primeiro escolha dividir por "Classificação" em uma das categorias de perdas.',
    notInClassification: "Não em classificação",
  },
  'select': {
    'allLines': 'Todas as linhas',
    'allMachines': 'Todas as máquinas',
    'selectMachine': 'Selecionar máquina',
    'selectLine': 'Selecionar linha',
    'clear': 'Apagar',
    'selectLineAreaOrPlant': 'Selecione fábrica, linha ou área'
  },
  'alarms': {
    'description': 'Descrição',
    'numberOfOccurances': 'Número',
    'duration': 'Duração',
    'sumOfSmallerAlarms': 'Soma de alarmes menores',
    'noDataInThisPeriod': 'Nenhum dado neste período'
  },
  'selectors': {
    'period': 'Período',
    'moment': 'Momento',
    'dateTo': 'Data até',
    'dateFrom': 'Data a partir de',
    'shift': 'Turno',
    'day': 'Dia',
    'week': 'Semana',
    'month': 'Mês',
    'today': 'Hoje',
    'yesterday': 'Ontem',
    'thisWeek': 'Esta semana',
    'previousWeek': 'Semana anterior',
    'thisMonth': 'Este mês',
    'previousMonth': 'Mês anterior',
    'selectLineAndMachine': 'Selecionar linha e máquina',
    'selectLine': 'Selecionar linha',
    'selectLineOrMachine': 'Selecionar linha ou máquina',
    'timeFilter': 'Filtro de tempo',
    'lastThreeMonths': 'Últimos 3 meses',
    'selectExactDates': 'Selecionar datas exatas',
    'selectDatesFromList': 'Selecionar datas da lista',
    'addElement': 'Adicionar elemento de fábrica ao relatório',
    'amount': 'Quantidade',
    year: "Ano",
    thisYear: "Este ano",
    previousYear: "Ano anterior",
  },
  'types': {
    'custom': 'Personalizar',
    'availability': 'Disponibilidade',
    'efficiency': 'Eficiência',
    'oee': 'OEE',
    'oeeLosses': 'Perdas de OEE',
    'oeeDetails': 'Detalhes do OEE',
    'production': 'Produção',
    'productionMap': 'Mapa da produção',
    'downtimes': 'Tempos de inatividade',
    'rootcauses': 'Causas raiz do tempo de inatividade',
    'alarms': 'Alarmes',
    'column': 'Coluna',
    'pie': 'Gráfico Pizza',
    'operatorStops': 'Operador para',
    'downtimeDuration': 'Duração do tempo de inatividade',
    'downtimeDurations': 'Durações do tempo de inatividade',
    'reasons': 'Motivos',
    'energy': 'Energia',
    'energySankey': 'Sankey',
    'energyHistory': 'Histórico de uso',
    'heatmap': 'Mapa de calor',
    'commentsFromLosses': 'Comentários de perdas',
    'typeOfLosses': 'Tipo de perdas',
    'reportsDownload': 'Baixar relatórios',
    comments: "Comentários de perdas",
    downtimeTracking: "Rastreamento de tempo de paralisação",
    energyTrends: "Tendências",
  },
  'operatorStop': {
    'waiting': 'Esperando por uma ação',
    'fixing': 'Duração da ação',
    'stopsBecauseOf': 'Parou porque',
    'timeSum': 'Duração total'
  },
  'rootcause': {
    'impact': {
      'title': 'Impacto',
      'desc': '(Duração do efeito / Duração da causa raiz)'
    }, 'effectDuration': 'Duração do efeito', 'rootcauseDuration': 'Duração da causa raiz'
  },
  'chart': {'toggleFullView': 'Ativar visualização completa'},
  'chooseEvent': 'Selecione o problema',
  'needToSelectEvent': 'Você precisa selecionar o problema',
  'splitBy': 'Dividido por',
  'minorStopsIncluded': 'Pequenas paradas (Incluídas na duração total)',
  'detailedEventsReport': 'Relatório detalhado de eventos',
  'siteOeeReport': 'Relatório de OEE do site',
  'speedLossReport': 'Relatório de perda de velocidade',
  'labourVarianceReport': 'Relatório de variação de mão de obra',
  'siteWeeklyScorecard': 'Indicador semanal do site',
  'siteMonthlyScorecard': 'Indicador mensal do site',
  'exportReport': 'Exportar relatório',
  'excludeUtilizationLoss': 'Excluir perdas de utilização',
  lineMachine: "Linha / Máquina",
  viewMinorStops: "Mostrar paradas menores",
  decrease: "diminuir",
  increase: "aumentar",
  seeDowntimesReport: "Ver relatório de tempos de parada",
  downloadReport: "Baixar relatório",
  numberOfItemsProduced: "Número de itens produzidos",
  toggleFullscreen: "Alternar tela cheia",
  compare: "Comparar",
  configureNewReportToCompare: "Configurar novo relatório para comparar",
  downtimesPerProducedUnit: "Paradas por unidade produzida",
  trackerOptions: "Opções de rastreamento de tempo de paralisação",
  expandCollapseAll: "Expandir/Recolher tudo",
  downtimeComments: {
    comment: "Comentário",
    downtimeElement: "Linha/Máquina",
    downtime: "Tempo de paralisação",
    time: "Tempo",
    duration: "Duração",
    commentAuthor: "Autor do comentário",
    showDowntime: "Mostrar Tempo de paralisação",
    details: "Detalhes",
  },
  downtimeTracker: {
    track: "Rastrear",
    resolutionForThisSelection: "Diminuir resolução para esta seleção",
    workspectrumForThisSelection:
      "Mostrar espectro de trabalho para esta seleção",
    view: "Ver",
    searchAProblem: "Procurar um problema...",
  },
  reportResolutionIsLongerThanReportPeriodInfo:
    "A resolução do relatório está configurada para um período mais longo do que o momento selecionado no relatório!",
};
