export default {
  report: 'Report',
  new: 'New',
  sum: 'Sum',
  time: 'Time',
  quantity: 'Quantity',
  aggregated: 'Aggregated',
  detailed: 'Detailed',
  showNAlarms: 'Show <b>{0}</b> longest alarms',
  showNLongest: 'Show <b>{0}</b> longest',
  showOthers: 'Show others',
  hideOthers: 'Hide others',
  sumOfSmaller: 'Sum of others',
  dropHereToCreateNew: 'Drag and drop here to create a new chart',
  completedOrders: 'Completed orders',
  history: 'History',
  usedEnergy: 'Used energy',
  splitBy: 'Split by',
  addPersonal: 'Personal dashboard',
  addFactory: 'Factory dashboard',
  unitConversionNotComplete: 'Unit conversion not complete',
  unitConversionComplete: 'Unit conversion complete',
  unableToFullyConvertUnitMessage: 'We are unable to convert all production to \'{unit}\', check packing structure of SKUs which were produced during this period',
  work: 'Work',
  plannedDowntime: 'Planned',
  unplannedDowntime: 'Unplanned',
  expected: 'Expected',
  rootElement: 'Root element',
  lineMachine: 'Line / Machine',
  performanceImpact: 'Performance loss',
  availabilityImpact: 'Availability loss',
  utilizationImpact: 'Utilization loss',
  minorStops: 'Minor stops',
  viewMinorStops: 'Show minor stops',
  minorStopsIncluded: 'Minor stops (Included in total duration)',
  detailedEventsReport: 'Detailed Events Report',
  siteOeeReport: 'Site OEE Report',
  speedLossReport: 'Speed Loss Report',
  labourVarianceReport: 'Labour Variance Report',
  siteWeeklyScorecard: 'Site weekly scorecard',
  siteMonthlyScorecard: 'Site monthly scorecard',
  exportReport: 'Export report',
  excludeUtilizationLoss: 'Exclude utilization loss',
  decrease: 'decrease',
  increase: 'increase',
  seeDowntimesReport: 'See downtimes report',
  downloadReport: 'Download report',
  numberOfItemsProduced: 'Number of items produced',
  compare: 'Compare',
  configureNewReportToCompare: 'Configure new report to compare',
  oeeLoss: {
    oeeLoss: 'OEE loss',
    timeLoss: 'Time loss',
    productionLoss: 'Production loss',
    undefinedIssue: 'Type of issue undefined',
    utilizationLosses: 'Utilization losses',
    availabilityLosses: 'Availability losess',
    performanceLosses: 'Performance losses',
    performanceStops: 'Performance loss downtimes',
    qualityLosses: 'Quality losses',
    qualityStops: 'Quality loss downtimes',
    speedLoss: 'Speed loss',
    qualityLoss: 'Quality loss',
    speedIncrease: 'Speed increase gain',
    combined: 'Combined',
    splitBy: 'Split by',
    byMachine: 'Line/machine',
    byProblem: 'Problem',
    byState: 'State',
    byClassification: 'Classification',
    base: 'Base time',
    classificationInfoTooltip: 'To use different type of classification, first choose split by “Classification” in one of the losses category.',
    notInClassification: 'Not in classification',
    time: {
      totalTime: 'Total time',
      scheduledTime: 'Scheduled time',
      operatingTime: 'Operating time',
      productionTime: 'Production time',
      effectiveTime: 'Effective time',
    },
    calculatedBasedOnTotalAvailabilityLoss: 'Calculated based on total availability loss',
  },
  select: {
    allLines: 'All lines',
    allMachines: 'All machines',
    selectMachine: 'Select machine',
    selectLine: 'Select line',
    clear: 'Clear',
    selectLineAreaOrPlant: 'Select factory, line or area',
  },
  alarms: {
    description: 'Description',
    numberOfOccurances: 'Number',
    duration: 'Duration',
    sumOfSmallerAlarms: 'Sum of smaller alarms',
    noDataInThisPeriod: 'No data in this period',
  },
  selectors: {
    amount: 'Amount',
    period: 'Period',
    moment: 'Moment',
    dateTo: 'Date to',
    dateFrom: 'Date from',
    shift: 'Shift',
    day: 'Day',
    lastDays: 'Last days',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This week',
    previousWeek: 'Previous week',
    thisMonth: 'This month',
    previousMonth: 'Previous month',
    selectLineAndMachine: 'Select line and machine',
    selectLine: 'Select line',
    selectLineOrMachine: 'Select line or machine',
    timeFilter: 'Time filter',
    lastThreeMonths: 'Last 3 months',
    selectExactDates: 'Select exact dates',
    selectDatesFromList: 'Select dates from list',
    addElement: 'Add factory element to report',
    thisYear: 'This year',
    previousYear: 'Previous year',
  },
  types: {
    custom: 'Custom',
    availability: 'Availability',
    efficiency: 'Efficiency',
    oee: 'OEE',
    oeeLosses: 'OEE losses',
    oeeDetails: 'OEE details',
    production: 'Production',
    productionMap: 'Production map',
    downtimes: 'Downtimes',
    rootcauses: 'Downtime Root Causes',
    comments: 'Comments from losses',
    alarms: 'Alarms',
    column: 'Column',
    pie: 'Pie',
    operatorStops: 'Operator stops',
    downtimeDuration: 'Downtime duration',
    downtimeDurations: 'Downtime durations',
    reasons: 'Reasons',
    energy: 'Energy',
    energySankey: 'Sankey',
    energyHistory: 'Usage history',
    reportsDownload: 'Reports download',
    heatmap: 'Heatmap',
    commentsFromLosses: 'Comments from losses',
    typeOfLosses: 'Type of losses',
    downtimeTracking: 'Downtime tracking',
    energyTrends: 'Trends',
  },
  operatorStop: {
    waiting: 'Waiting for an action',
    fixing: 'Action duration',
    stopsBecauseOf: 'Stops because of',
    timeSum: 'Total duration',
  },
  rootcause: {
    impact: {
      title: 'Impact',
      desc: '(Effect duration / Root cause duration)',
    },
    effectDuration: 'Effect duration',
    rootcauseDuration: 'Root cause duration',
  },
  chart: {
    toggleFullView: 'Toggle full view',
  },
  chooseEvent: 'Select problem',
  needToSelectEvent: 'You need to select problem',
  downtimesPerProducedUnit: 'Downtimes per produced unit',
  trackerOptions: 'Downtime tracking options',
  expandCollapseAll: 'Expand/Collapse all',
  downtimeComments: {
    comment: 'Comment',
    downtimeElement: 'Line/Machine',
    downtime: 'Downtime',
    time: 'Time',
    duration: 'Duration',
    commentAuthor: 'Comment author',
    showDowntime: 'Show Downtime',
    details: 'Details',
  },
  downtimeTracker: {
    track: 'Track',
    resolutionForThisSelection: 'Narrow resolution for this selection',
    workspectrumForThisSelection: 'Show work spectrum for this selection',
    view: 'View',
    searchAProblem: 'Search a problem...'
  },
  reportResolutionIsLongerThanReportPeriodInfo: 'Report resolution is set to longer period than selected report moment!'
};
